import * as React from 'react'
import useSWR, { useSWRConfig } from 'swr'
import useSWRImmutable from 'swr/immutable'
import { useIntl } from 'react-intl'
import Client from '@avcan/utils/api/clients/weather'
import * as Config from './config'
import * as Formats from '@avcan/constants/intl/formats'

const client = new Client(Config.weather)

export default client

export function useStation(id) {
    const key = createKey(id)

    return useSWRImmutable(key, () => client.station(id))
}

export function useStations() {
    const key = createKey()
    const { mutate } = useSWRConfig()
    const swr = useSWRImmutable(key, () => client.stations())
    const { data } = swr

    React.useEffect(() => {
        if (!Array.isArray(data)) {
            return
        }

        for (const station of data) {
            mutate(createKey(station.id), station)
        }
    }, [data])

    return swr
}

export function useMeasurements(stationId) {
    const key = createKey(stationId, 'measurements')
    const { data: station } = useStation(stationId)
    const swr = useSWR(key, () => client.measurements(stationId))
    const { data: measurements } = swr
    const intl = useIntl()

    return {
        ...swr,
        data: React.useMemo(() => {
            if (!measurements || !station) {
                return
            }

            const { timezone: timeZone } = station
            const formatDate = { ...Formats.DATE, timeZone }
            const formatTime = { ...Formats.TIME, timeZone }
            const formatDateTime = { ...Formats.DATETIME, timeZone }
            function createMeasurements(measurements) {
                const dateTime = new Date(measurements.measurementDateTime)

                return {
                    ...measurements,
                    measurementDateTime: dateTime,
                    measurementDateDisplay: intl.formatDate(dateTime, formatDate),
                    measurementTimeDisplay: intl.formatDate(dateTime, formatTime),
                    measurementDateTimeDisplay: intl.formatDate(dateTime, formatDateTime),
                }
            }
            function sorter(a, b) {
                // Please note: b - a to make it descendant, ie. newest measurements first
                return b.measurementDateTime - a.measurementDateTime
            }

            return measurements.map(createMeasurements).sort(sorter)
        }, [measurements, station, intl]),
    }
}

// Utils
function createKey(...items) {
    return ['stations', ...items]
}
