import * as React from 'react'
import { isNumber, isFinite } from 'lodash'

export function useShouldShowGraph(data, prop) {
    return React.useMemo(() => data.some(object => isFiniteNumber(object[prop])), [data, prop])
}

export function useFilteredDataset(data, prop) {
    return React.useMemo(() => data.filter(object => isFiniteNumber(object[prop])), [data, prop])
}

function isFiniteNumber(value) {
    return isNumber(value) && isFinite(value)
}
