import * as React from 'react'
import { useIntl } from 'react-intl'
import * as Formats from '@avcan/constants/intl/formats'

export function getDateExtent(data) {
    const dates = data.map(observation => observation.measurementDateTime)

    return {
        min: new Date(Math.min(...dates)),
        max: new Date(Math.max(...dates)),
    }
}

export function useTickFormatter() {
    const intl = useIntl()

    return React.useCallback(
        (date, index) => {
            const time = intl.formatDate(date, Formats.TIME)

            if (index === 0 || date.getHours() === 0) {
                return time + '\n' + intl.formatDate(date, Formats.DATE_COMPACT)
            }

            return time
        },
        [intl]
    )
}

export const scatterEvents = [
    {
        target: 'data',
        eventHandlers: {
            onMouseOver() {
                return [
                    {
                        target: 'data',
                        mutation({ style }) {
                            return {
                                style: {
                                    ...style,
                                    fill: style.stroke,
                                },
                            }
                        },
                    },
                    {
                        target: 'labels',
                        mutation() {
                            return {
                                active: true,
                            }
                        },
                    },
                ]
            },
            onMouseOut() {
                return [
                    {
                        target: 'data',
                        mutation({ style }) {
                            return {
                                style: {
                                    ...style,
                                    fill: 'white',
                                },
                            }
                        },
                    },
                    {
                        target: 'labels',
                        mutation() {
                            return {
                                active: false,
                            }
                        },
                    },
                ]
            },
        },
    },
]

export const barEvents = [
    {
        target: 'data',
        eventHandlers: {
            onMouseOver() {
                return [
                    {
                        target: 'data',
                        mutation({ style }) {
                            return {
                                style: {
                                    ...style,
                                    opacity: 0.5,
                                },
                            }
                        },
                    },
                    {
                        target: 'labels',
                        mutation() {
                            return {
                                active: true,
                            }
                        },
                    },
                ]
            },
            onMouseOut() {
                return [
                    {
                        target: 'data',
                        mutation({ style }) {
                            return {
                                style: {
                                    ...style,
                                    opacity: 1,
                                },
                            }
                        },
                    },
                    {
                        target: 'labels',
                        mutation() {
                            return {
                                active: false,
                            }
                        },
                    },
                ]
            },
        },
    },
]
